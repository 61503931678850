#about-main {
    min-height: 80vh;
}

#About #profile {
    border-width: 10px!important;
    box-shadow: inset 0 0 0 12px rgba(0,0,0,1);
    border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
    animation: morph 8s ease-in-out 1s infinite;
    border-color: var(--main-highlight-color)!important;
}

.about-title-section {
    display: flex;
    justify-content: center;
    align-items: center;
}

#About p {
    margin-bottom: 0;
}

#About .header {
    zoom: 125%;
    padding-left: 0!important;
}

#About h2 {
    margin-top: 0!important;
}

#About .header:before {
    background-color: transparent;
}

.dot {
    height: 50px;
    width: 50px;
    background-color: var(--main-highlight-color);
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    content: "";
    z-index: -1;
}

.top-right {
    top: 0;
    right: 0;
}

.bottom-left {
    bottom: 0;
    left: 0;
    height: 100px;
    width: 100px;
}

.relative {
    position: relative;
}

@keyframes morph {
    0% {
        border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
    }
    50% {
        border-radius: 30% 60% 70% 40%/50% 60% 30% 60%;
    }
    100% {
        border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
    }
}