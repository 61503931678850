.project-banner {
    align-items: center;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    box-shadow: inset 0 0 0 1000px rgba(0,0,0,.3);
    display: flex;
    height: 50vh;
    justify-content: center;
    width: 100%;
}

.project-banner h2 {
    text-shadow: 2px 3px 5px rgba(185, 147, 70, 0.5);
}

#project-page tbody, tfoot, tr, th, td {
    background-color: transparent!important;
    border: 0!important;
    color: #fff!important;
}

#project-page th,td {
    text-align: left;
    padding-right: 2em;
    padding-left: 2em;
}

.project-description {
    text-align: left;
    margin: auto;
}

.section {
    margin-top: 3rem;
    margin-bottom: 3rem;
}

.section-title {
    position: relative;
    margin-bottom: 2rem;
    width: fit-content;
}

.card-title strong {
    text-decoration-thickness: 4px;
}

.section-body {
    font-size: 1.25rem!important;
    font-weight: lighter;
}

iframe {
    aspect-ratio: 16/9;
}

.section p, .section ul, .section li  {
    font-weight: lighter!important;
}

.carousel-inner:before {
    -webkit-box-shadow: inset 0 0 5rem rgba(0, 0, 0, 0.8);
    box-shadow: inset 0 0 5rem rgba(0, 0, 0, 0.8);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    z-index: 1;
}

#project-page .carousel-control-next,  #project-page .carousel-indicators, #project-page .carousel-control-prev {
    z-index: 2;
}

.carousel img {
    aspect-ratio: 16/9;
    object-fit: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.carousel h4 {
    backdrop-filter: blur(2px);
}

#project-page .carousel-indicators button {
    background-color: var(--main-highlight-color)!important;
    border: 1px solid var(--main-bg-color);
}

#project-page #download-btn {
    background-color: #c6a63d;
    color: var(--main-text-color)!important;
}

#project-page #download-btn:hover {
    background-color: #e3b150;
}