#home h2 {
    font-weight: normal!important;
}

#home {

}

#home .social {
    position: relative;
    margin-top: 60px;
}

.Typewriter {
    display: inline-block;
    font-family: "League Spartan", sans-serif;
}

.Typewriter__wrapper {
    font-size: xx-large;
    font-weight: bold;
    color: rgb(198, 166, 61);
}

.Typewriter__cursor {
    font-size: xx-large;
    font-weight: bold;
}