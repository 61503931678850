#projects .card img {
    aspect-ratio: 4/3;
    object-fit: cover;
}

#projects .social-icon {
    margin: 4px;
    width: 40px!important;
    height: 40px!important;
}

#projects .card {
    box-shadow: 0 13px 27px -5px hsla(240, 30.1%, 28%, 0.25), 0 8px 16px -8px hsla(0, 0%, 0%, 0.3), 0 -6px 16px -6px hsla(0, 0%, 0%, 0.03);
    transition: all ease 200ms;
    height: 700px;
    overflow: hidden;
}

#projects .card:hover {
    transform: scale(1.03);
    box-shadow: 0 13px 40px -5px hsla(240, 30.1%, 28%, 0.12), 0 8px 32px -8px hsla(0, 0%, 0%, 0.14), 0 -6px 32px -6px hsla(0, 0%, 0%, 0.02);
}

#projects .card-body {
    overflow: hidden;
}

#projects .card-text {
    overflow: hidden;
    height: 90%;
}

#projects .card-footer {
    border-color: rgb(var(--main-text-color));
}