footer {
    background: rgb(33,37,41)!important;
}

footer span {
    color: rgb(222, 222, 222);
}

footer .social {
    display: flex;
    justify-content: center;
    padding: 0.5rem;
}

footer .social-icon {
    margin-bottom: 0!important;
    font-size: 1.5em;
    transition: all ease-in-out .2s;
}

