.navbar {
    font-family: "Poppins", Arial, sans-serif;
    font-weight: 600;
    line-height: 1.4;
}

#navbar {
    background-color: rgba(33,37,41, 0.98)!important;
}

.navbar-custom .navbar-brand img {
    border-color: var(--main-highlight-color)!important;
}

.navbar-custom {
}

.navbar-collapse {
    flex-grow: initial!important;
}

.navbar__link {
    margin-left: 0.75em;
    margin-right: 0.75em;
    cursor: pointer;
    text-decoration: none;
    letter-spacing: .1em;
    text-indent: .3em;
    border-bottom: 3px solid transparent;
    text-transform: uppercase;
    font-size: 0.85rem;
}

.navbar__link::after {
    transition: all ease-in-out .2s;
    background: none repeat scroll 0 0;
    content: "";
    display: block;
    margin-top: 2px;
    height: 3px;
    width: 0;
}

.navbar__link:hover {
    text-decoration: none;
}

.navbar__link:hover::after {
    visibility: visible;
    width: 40%;
}

.navbar__link--active::after {
    transition: all ease-in-out .2s;
    width: 100%;
}

.navbar__link--active:hover::after {
    width: 100%;
}

.navbar-toggler {
    border-color: #c6a63d!important;
}

.navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(198, 166, 61, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E")!important;
}

.custom-available-bullet {
    animation: blinker 1s linear infinite;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background-color: #39b54a;
    margin-bottom: 2px;
    margin-right: 6px;
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {

    .navbar__link {
        margin-left: 0.4em;
        margin-right: 0.4em;
        font-size: 0.9em;
        cursor: pointer;
        letter-spacing: .1em;
        text-indent: .2em;
    }
}

@media (max-width: 768px) {

    .navbar__link::after {
        background: none;
        content: "";
        display: none;
        margin-top: 0;
        height: 0;
        width: 0;
    }

    .navbar__link--active::after {
        width: 0;
    }

    .navbar__link--active:hover::after {
        width: 0;
    }

    .navbar__link:hover::after {
        width: 0;
    }
}

#env-mode {
    position: absolute;
    bottom: 5px;
    left: 50%;
    transform: translate(-50%, 0);
    font-size: 0.8rem;
    color: var(--main-highlight-color);
}