html, body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', "Poppins", "Cormorant",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 100%;
  overflow: auto;
  scroll-behavior: smooth;
}

#root {
  height: 100%;
  overflow: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1, h2, h3, h4 {
  font-family: "League Spartan", sans-serif;
  font-weight: bold!important;
}

p, th, td, ul, li, span, time, small {
  font-family: "League Spartan", sans-serif;
}

p {
  font-weight: normal;
}

a {
  color: var(--main-highlight-color);
  transition: all ease-in-out .2s;
}

a:hover {
  color: var(--main-text-color);
}

time, small {
    font-weight: lighter;
}

strong {
  position: relative;
  font-weight: bold!important;
  color: var(--main-text-color) !important;
  text-shadow: 0 0 10px rgba(198, 166, 61, 0.2);
}

::-webkit-scrollbar-track
{
  border-radius: 10px;
}

::-webkit-scrollbar
{
  width: 12px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb
{
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: var(--main-highlight-color);
}