
:root {
  --main-bg-color: rgb(28, 28, 28);
  --second-bg-color: rgb(33, 37, 41);
  --main-text-color: rgb(238, 238, 238);
  --main-highlight-color: #c6a63d;
}

#lost {
  min-height: 60vh;
}

.App {
  text-align: center;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  flex-direction: row;
  overflow: auto;
  width: 100%;
}

.MainApp {

}

.main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  min-height: 100vh;
}

.section-content-container {
  width: 100%!important;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
}

.highlight-scribble-1 {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 45%;
  top: auto;
  bottom: -25%;
}

section.timeline-card-content {
  justify-content: center;
}

section.timeline-card-content p.card-title {
  font-size: 1.5em;
  margin-top: 25px;
}

section.timeline-card-content p.card-sub-title {
  font-size: 1.25em;
  margin-top: 10px;
}

section.timeline-card-content div.card-description > p {
  font-size: 1.1em;
  margin-top: 10px;
  font-weight: 500;
}

.header {
  font-size: 4rem;
  font-weight: 700;
  font-variant: small-caps slashed-zero;
}

.fit-content {
  width: fit-content;
}

.underline {
  font-variant: small-caps slashed-zero;
}

.highlight {
  position: relative;
}

.highlight::before {
  background-color: var(--main-highlight-color);

  content: "";
  position: absolute;
  width: calc(100% + 10px);
  height: 25%;
  left: -3px;
  bottom: 2px;
  z-index: -1;
  transform: rotate(-2deg);
}

#skills .header {
  margin-bottom: 30px!important;
}

#skills h3 {
  font-size: 3em!important;
}

#skills p {
  font-size: large;
}

.MainApp .btn-outline-warning {
  color: var(--main-highlight-color)!important;
  border-color: var(--main-highlight-color)!important;
}

.MainApp .btn-outline-warning:hover {
  color: var(--second-bg-color)!important;
  border-color: var(--main-highlight-color)!important;
}

.MainApp .border {
  border-color: var(--main-highlight-color)!important;
}

.social-svg:hover {
  border: var(--main-highlight-color) solid 4px;
}

#social-perm {
  bottom: 10%;
  right: 3%;
}

#social-perm .social {
  display: grid;
}

#projects .badge {
  font-weight: normal!important;
  color: var(--main-text-color);
  font-size: 0.8rem;
}


