.entry .body{
    margin: 0 0 1em;
}

@media only screen and (max-width: 768px) {
    .item-title {
        margin-top: 10px !important;
    }
}

.timeline-item-date {
    background: none!important;
    clip-path: none!important;
}

.timeline-item-dateinner {
    clip-path: none!important;
    border-radius: 0.2rem;
    font-weight: normal!important;
}

.entry .title:before {
    background-color: #c6a63d;
}

#experience h4 {
    color: var(--main-highlight-color)!important;
}