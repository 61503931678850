.vertical {
    justify-content: center;
}

.vertical_alternating {
    justify-content: center;
}

@media only screen and (max-width: 578px) {
    div.vertical-item-row > div:nth-child(1) {
        width: 20% !important;
    }
    div.vertical-item-row > div:nth-child(2) {
        width: 65% !important;
    }
    div.vertical-item-row > div:nth-child(3) {
        width: 15% !important;
    }
}

#education .timeline-item-title {
    color : #eee;
    text-align: center;
}

#education .timeline-verticalstyles__TimelineVerticalWrapper-sc-1427v1d-0 {
    padding: 0;
}

#education .using-icon {
    width: 32px;
    height: 32px;
    background-color: var(--main-highlight-color);
}

#education .card-sub-title {
    font-size: 1.25rem!important;
    font-weight: bold;
    margin-top: 0!important;
}

.timeline-card-content {
    padding: 1rem;
}

#education .card-description {
    font-size: 1.1em;
    font-weight: lighter;
    align-items: start;
    margin: 0;
    padding: 0 0.5rem 0.5rem;
}


#education .card-description ul {
    margin-bottom: 0;
}

#major {
    font-weight: lighter;
    margin-top: 0;
    color: #f1cc54;
}

@media (max-width: 768px) {
    #education .timeline-verticalstyles__VerticalCircleWrapper-sc-1427v1d-2 {
        display: none;
    }

    #education .vertical-item-row {
        margin-bottom: 10px;
    }

    #education .timeline-verticalstyles__TimelineTitleWrapper-sc-1427v1d-5 {
        padding-left: 15px;
    }
}

